<template>
  <div class="profileScreen">
    <div class="teamProfile">
      <div class="profileImage">
        <img src="../../../assets/peoplePlaceholder.jpg" alt="Team image"/>
        <router-link :to="{name: 'settings.profile.edit'}" class="editProfile">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
            <defs>
              <clipPath id="clip-Edit">
                <rect width="18" height="19"/>
              </clipPath>
            </defs>
            <g id="Edit" clip-path="url(#clip-Edit)">
              <g id="edit-black-18dp" transform="translate(0.2 0.4)">
                <path id="Path_1228" data-name="Path 1228" d="M0,0H19.2V19.2H0Z" fill="none"/>
                <path id="Path_1229" data-name="Path 1229"
                      d="M3,14.4v3H6l8.848-8.848-3-3ZM17.168,6.232a.8.8,0,0,0,0-1.128L15.3,3.232a.8.8,0,0,0-1.128,0L12.7,4.7l3,3,1.464-1.464Z"
                      transform="translate(-0.6 -0.6)" fill="#707070"/>
              </g>
            </g>
          </svg>
          Edit profile
        </router-link>
        <a @click="$router.go(-1)" class="closeLink" type="button">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
               class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 352 512">
            <path fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
          </svg>
        </a>
      </div>
      <div class="container">
        <div class="profileHeader pt-5 mt-1">
          <h2>{{ user.name }}</h2>
          <p>{{ user.slogan }}</p>
          <p class="highlight" v-if="user.temMem">Team members: {{ user.temMem.length }}</p>
        </div>
        <!--<div class="members d-flex justify-content-around pt-2 pb-5">
            <div class="imgWrapper">
                <img src="../assets/personPlaceholder.jpg" alt="Team member" />
            </div>
            <div class="imgWrapper">
                <img src="../assets/personPlaceholder.jpg" alt="Team member" />
            </div>
            <div class="imgWrapper">
                <img src="../assets/personPlaceholder.jpg" alt="Team member" />
            </div>
            <div class="imgWrapper">
                <img src="../assets/personPlaceholder.jpg" alt="Team member" />
            </div>
        </div>-->
        <div class="importantInfo">
          <p>
            Phone: <span class="highlight">{{ user.phone }}</span> <br>
            Instant messages: <span class="highlight">{{ user.message_level }}</span> <br>
            Car registration: <span class="highlight">{{ user.car_reg }}</span>
          </p>
        </div>
        <div class="teamDesc" v-if="user.description">
          <p>
            {{ user.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        config: null,
        clickOnEdit: false,
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      }
    },
    components: {
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .teamProfile {
    height: 100%;

    @media screen and (min-width: 768px) {
      width: 90%;
    }

    @media screen and (min-width: 1000px) {
      width: 93%;
    }

    @media screen and (min-width: 1300px) {
      width: 95%;
    }

    .highlight {
      color: $secondary;
      font-weight: 500;
    }

    .profileImage {
      width: 100%;

      @media screen and (min-width: 768px) {
        margin-left: 80px;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;

        @media screen and (min-width: 768px) {
          height: 300px;
        }
      }

      .editProfile {
        color: $body-color;
        background-color: #e8e8e8;
        padding: 8px;
        position: absolute;
        top: 180px;
        display: block;
        z-index: 100;
        text-decoration: none;
        left: 50%;
        transform: translate(-50%);

        @media screen and (min-width: 768px) {
          top: 276px;
          left: auto;
          transform: none;
          right: 12px;
        }

        svg {
          margin-right: 4px;
          margin-top: -2px;
        }
      }

      .closeLink {
        position: absolute;
        right: 12px;
        top: 180px;
        background-color: white;
        padding: 6px 12px;
        text-decoration: none;
        color: #707070;
        box-shadow: 0px 3px 6px #e8e8e8;

        @media screen and (min-width: 768px) {
          top: 276px;
          top: 20px;
        }

        svg {
          fill: #707070;
          width: 16px;
          height: 16px;
        }
      }
    }

    .container {
      @media screen and (min-width: 768px) {
        margin-left: 80px;
      }
    }

    .profileHeader {
      text-align: center;

      h2 {
        color: $body-color;
        font-weight: 600;
      }
    }

    .members {
      .imgWrapper {
        width: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        /* IE 9 */
        -webkit-transform: rotate(45deg);
        /* Safari and Chrome */
        position: relative;

        /* non-essential styling */
        -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, .05);
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, .05);

        img {
          transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          /* IE 9 */
          -webkit-transform: rotate(-45deg);
          /* Safari and Chrome */
          width: 141%;
          height: 141%;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
    }

    .teamDesc {
      padding-bottom: 100px;
    }

  }
</style>
